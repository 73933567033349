.container{
  padding: 100px;
}
.text{
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
.btn{
  width: 10%;
}
.button-border{
  border: none;
}
.editor-box{
  height: 300px;
  margin-bottom: 50px;
}
.end-text{
  margin-top: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}