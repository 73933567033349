.header-img {
  display: flex;
  justify-content: center;
}
.header-title {
  margin-top: 50px;
  font-size: 28px;
  color: gray;
  font-weight: lighter;
  margin-bottom: 0px;
}
.header-description {
  margin-top: 0px;
  color: #6d7072;
  margin-bottom: 20px;
}
#sq_101 > div > div > button{
  width: 150px;
}
#sq_116 > div > div > button{
  width: 150px;
}
#sq_112 > div:nth-child(2) > div > button.sv_q_file_remove_button{
  display: none;
}
#sq_112 > div:nth-child(2) > div > div.sv-file__decorator{
  display: flex;
}
.loader{
  display: none;
  width: 100%;
  text-align: center;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_validbox:not([style*="display: none"]):not([style*="display:none"]) {
  margin: 1em 0;
  padding: 1em;
}

.sv_main .sv_q_validbox:not([style*="display: none"]):not([style*="display:none"]) {
  border: 1px solid #c3e6cb;
  color: #155724;
  background-color: #d4edda;
}

.sv_q_file_remove_button {
  display: none !important;
}
#kiora-form-sign-iframe-container {
  height: 600px;
  display: none;
}
#kiora-form-sign-iframe-container iframe {
  margin: 20px 0;
  width: 100%;
  height: 100%;
}